import React, { useState } from 'react';
import styles from './generate.module.scss';
import { Input, Form, Tooltip, Button, Select, Popover } from 'antd';
import { VaTitle } from '../typography'; // Ensure VaTitle is exported correctly
import {
  FileTextOutlined,
  QuestionCircleOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import toast from 'react-hot-toast';

const { TextArea } = Input;

const helpContent = (
  <div>
    <p>
      Hey there! Feel free to <strong>edit</strong> and <strong>view</strong>{' '}
      your text based on your needs.
    </p>
  </div>
);

export default function ContentText({
  selectedMarkupText,
  onMarkupTextChange,
  handleSceneTitleChange,
  selectedTitle,
  sceneType,
  handleSceneTypeChange,
  onFinishRegenerateScene
}) {
  const options = [
    { value: 'CODE_SLIDE', label: 'Code' },
    { value: 'CONTENT_SLIDE', label: 'Content' },
    { value: 'CONTENT_SLIDE_WITH_IMAGE', label: 'Content with Image' },
    { value: 'TITLE_SLIDE', label: 'Title' },
    { value: 'IMAGE_ONLY_SLIDE', label: 'Image' }
  ];

  const [loadings, setLoadings] = useState([false]);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
      toast.success('Regenerated Scene Successfully');
    }, 3000);
  };

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.contentActions}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <FileTextOutlined style={{ marginRight: '8px' }} />
          <VaTitle level={5} text="Content Text" />
          <Popover content={helpContent} title="Help">
            <QuestionCircleOutlined
              style={{
                marginLeft: '280px',
                fontSize: '16px',
                cursor: 'pointer'
              }}
            />
          </Popover>
        </div>
        <div className={styles.videoText} style={{width:'420px'}}>
          <TextArea
            rows={8}
            placeholder="maxLength is 6"
            value={selectedMarkupText}
            name="sceneMarkupText"
            onChange={(event) => onMarkupTextChange(event)}
          />
        </div>
        <Form.Item
          label="Scene Title:"
          style={{ width: '100%', marginBottom: '1rem', marginTop:'20px' }}
        >
          <Input value={selectedTitle} onChange={handleSceneTitleChange} />
        </Form.Item>
        {/* <div style={{ width: '100%', marginBottom: '0.5rem' }}> */}
        <Form.Item
          label="Scene Type:"
          style={{ width: '100%', marginBottom: '0.75rem' }}
        >
          <Select
            value={sceneType}
            onChange={handleSceneTypeChange}
            options={options}
          />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {/* <Tooltip title="Regenerate Scene" placement="left"> */}
          <Button
            style={{ alignItems: 'center' , marginLeft:'250px'}}
            type="primary"
            icon={<ReloadOutlined />}
            loading={loadings[0]}
            onClick={() => {
              enterLoading(0);
              onFinishRegenerateScene();
            }}
          >
            Regenerate Scene
          </Button>
          {/* </Tooltip> */}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
