import React from 'react';
import {
  AbsoluteFill,
  spring,
  useCurrentFrame,
  useVideoConfig,
  Img
} from 'remotion';
import {
  Animated,
  Animation,
  Fade,
  Move,
  Scale,
  Size
} from 'remotion-animated';


const TITLE_DURATION = 60;

const RemotionImageSlide = ({
  title,
  content,
  background,
  animationStyle,
  imageId
}) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  var imageFile = '';

  if (imageId !== '' && imageId !== null) {
    imageFile = `https://va-sc-images.s3.amazonaws.com/${imageId}`;
  }
  // else {
  //   imageFile = `https://va-sc-images.s3.amazonaws.com/ea3d49a7.png`;
  // }

  const animations = {
    fadeIn: {
      opacity: spring({ frame, fps, from: 0, to: 1, durationInFrames: 30 })
    },
    slideUp: {
      transform: `translateY(${spring({
        frame,
        fps,
        from: 100,
        to: 0,
        durationInFrames: 30
      })}%)`
    }
    // Add more animations as needed
  };

  return (
    <AbsoluteFill
      className="items-center justify-center"
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%'
      }}
    >
      <Animated
        absolute
        animations={[
          Scale({ by: 1.75, initial: 10 }),
          Fade({ to: 1, initial: 0 })
        ]}
        style={{
          top: '10%'
        }}
      >
        <div className="text-gray-200 text-5xl font-bold leading-relaxed">
          {title}
        </div>
      </Animated>

      <AbsoluteFill
        className="items-center justify-center"
        style={{
          width: '100%',
          height: '100%',
          top: '10%',
          margin: '2rem',
          overflow: 'hidden'
        }}
      >
        <Animated
          animations={[
            Fade({ to: 1, initial: 0, start: 0, duration: TITLE_DURATION * 2 })
          ]}
        >
          {imageId ? <Img src={imageFile} /> : null}
        </Animated>
      </AbsoluteFill>
    </AbsoluteFill>
  );
};

export default RemotionImageSlide;