import React from 'react';
import styles from './settings.module.scss';
import { VaTitle } from 'shared/components/typography';
import { Button, Form, Input, message, Upload, Radio } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import toast from 'react-hot-toast';

export default function Settings() {
  const [form] = Form.useForm();

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none'
      }}
      type="button"
    >
      <div
        style={{
          marginTop: 8
        }}
      >
        Upload
      </div>
    </button>
  );
  
  const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      toast.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
    
    }
  };

  const handleFinish = (values) => {

  };

  return (
    <div className={styles.wrapper}>
      <VaTitle level={5} text='Profile Management'/>
      <div className={styles.cardWrapper}>
        <div className={styles.card}>
          <VaTitle level={5} text='Profile Update'/>
          <div className={styles.profile}>
            <Upload
              name="avatar"
              listType="picture-circle"
              className="avatar-uploader"
              showUploadList={false}
              action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {
                uploadButton
              }
            </Upload>
          </div>
          <div  className={styles.profileForm}>
            <Form
              name="basic"
              layout='vertical'
              onFinish={handleFinish}
              form={form}
              autoComplete="off"
            >
              <Form.Item
                label="First name"
                name="firstName"
            
                rules={[
                  {
                    required: true,
                    message: 'Please input your First Name!'
                  }
                ]}
              >
                <Input placeholder="Enter email"/>
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastName"
            
                rules={[
                  {
                    required: true,
                    message: 'Please input your lastName!'
                  }
                ]}
              >
                <Input placeholder="Enter email"/>
              </Form.Item>
              <Form.Item
                label="Work email"
                name="email"
            
                rules={[
                  {
                    required: true,
                    message: 'Please input your Work email!'
                  }
                ]}
              >
                <Input placeholder="Enter email"/>
              </Form.Item>

            </Form>
          </div>
          <div className={styles.footer}>
            <Button type='primary'>Save Changes</Button>
          </div>
        </div>
        <div className={styles.card}>
          <VaTitle level={5} text='Password Update'/>
          <div  className={styles.passwordForm}>
            <Form
              name="basic"
              layout='vertical'
              onFinish={handleFinish}
              form={form}
              autoComplete="off"
            >
              <Form.Item
                label="Current Password"
                name="currentPassword"
            
                rules={[
                  {
                    required: true,
                    message: 'Please input your currentPassword!'
                  }
                ]}
              >
                <Input.Password placeholder="Enter password"/>
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
            
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!'
                  }
                ]}
              >
                <Input.Password placeholder="Enter password"/>
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="password"
            
                rules={[
                  {
                    required: true,
                    message: 'Please input your Confirm password!'
                  }
                ]}
              >
                <Input.Password placeholder="Enter password"/>
              </Form.Item>
            </Form>
          </div>
          <div className={styles.footer}>
            <Button type='primary'>Save Changes</Button>
          </div>
        </div>
      </div>
      <div className={styles.cardWrapper}>
        <div className={styles.card}>
          <VaTitle level={5} text="Additional Options" />
          <div className={styles.watermarkForm}>
            <Form
              name="watermarkUpload"
              layout="vertical"
              onFinish={handleFinish}
              form={form}
              autoComplete="off"
            >
              <Form.Item
                label="Upload Watermark"
                name="watermark"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                style={{marginTop:'20px'}}
                rules={[
                  {
                    required: true,
                    message: 'Please upload your watermark image!',
                  },
                ]}
              >
                <Upload
                  name="watermark"
                  listType="picture"
                  beforeUpload={() => false} // Prevent auto-upload
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Form>
          </div>
          <div className={styles.footer}>
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
