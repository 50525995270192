import React, {useEffect, useState } from 'react';
import styles from './generate.module.scss';
import { Image, Radio } from 'antd';

export default function Background({ selectedBackground, onBackgroundSelect, aspectRatio }) {
  const [currentAspectRatio, setCurrentAspectRatio] = useState(aspectRatio);
  console.log(selectedBackground);

  const imageData16x9 = [
    { id: 1, image_name: 'bg_1' },
    { id: 2, image_name: 'bg_2' },
    { id: 3, image_name: 'bg_3' },
    { id: 4, image_name: 'bg_4' },
    { id: 5, image_name: 'bg_5' },
    { id: 6, image_name: 'bg_6' },
    { id: 7, image_name: 'bg_7' },
    { id: 8, image_name: 'bg_8' },
    { id: 9, image_name: 'bg_9' },
    { id: 10, image_name: 'bg_10' },
    { id: 11, image_name: 'bg_11' },
    { id: 12, image_name: 'bg_12' },
    { id: 13, image_name: 'bg_13' },
    { id: 14, image_name: 'bg_14' },
    { id: 15, image_name: 'bg_15' },
    { id: 16, image_name: 'bg_16' },
    { id: 17, image_name: 'bg_17' },
    { id: 18, image_name: 'bg_18' },
    { id: 19, image_name: 'bg_19' },
    { id: 20, image_name: 'bg_20' },
    { id: 21, image_name: 'bg_21' },
    { id: 22, image_name: 'bg_22' },
    { id: 23, image_name: 'bg_23' },
    { id: 24, image_name: 'bg_24' },
    { id: 25, image_name: 'bg_25' },
    { id: 26, image_name: 'bg_26' },
    { id: 27, image_name: 'bg_27' },
    { id: 28, image_name: 'bg_28' },
    { id: 29, image_name: 'bg_29' },
    { id: 30, image_name: 'bg_30' },
    { id: 31, image_name: 'bg_31' },
    { id: 32, image_name: 'bg_32' },
    { id: 33, image_name: 'bg_33' },
    { id: 34, image_name: 'bg_34' },
    { id: 35, image_name: 'bg_35' },
    { id: 36, image_name: 'bg_36' },
    { id: 37, image_name: 'bg_37' },
    { id: 38, image_name: 'bg_38' },
    { id: 39, image_name: 'bg_39' }
  ];
  const imageData9x16 = [
    { id: 1, image_name: 'bg_1_9x16' },
    { id: 2, image_name: 'bg_2_9x16' },
    { id: 3, image_name: 'bg_3_9x16' },
    { id: 4, image_name: 'bg_4_9x16' },
    { id: 5, image_name: 'bg_5_9x16' },
    { id: 6, image_name: 'bg_6_9x16' },
    { id: 7, image_name: 'bg_7_9x16' },
    { id: 8, image_name: 'bg_8_9x16' },
    { id: 9, image_name: 'bg_9_9x16' },
    { id: 10, image_name: 'bg_10_9x16' },
    { id: 11, image_name: 'bg_11_9x16' },
    { id: 12, image_name: 'bg_12_9x16' },
    { id: 13, image_name: 'bg_13_9x16' },
    { id: 14, image_name: 'bg_14_9x16' },
    { id: 15, image_name: 'bg_15_9x16' },
    { id: 16, image_name: 'bg_16_9x16' },
    { id: 17, image_name: 'bg_17_9x16' },
    { id: 18, image_name: 'bg_18_9x16' },
    { id: 19, image_name: 'bg_19_9x16' },
    { id: 20, image_name: 'bg_20_9x16' },
    { id: 21, image_name: 'bg_21_9x16' },
    { id: 22, image_name: 'bg_22_9x16' },
    { id: 23, image_name: 'bg_23_9x16' },
    { id: 24, image_name: 'bg_24_9x16' },
    { id: 25, image_name: 'bg_25_9x16' },
    { id: 26, image_name: 'bg_26_9x16' },
    { id: 27, image_name: 'bg_27_9x16' },
    { id: 28, image_name: 'bg_28_9x16' },
    { id: 29, image_name: 'bg_29_9x16' },
    { id: 30, image_name: 'bg_30_9x16' },
    { id: 31, image_name: 'bg_31_9x16' },
    { id: 32, image_name: 'bg_32_9x16' },
    { id: 33, image_name: 'bg_33_9x16' },
    { id: 34, image_name: 'bg_34_9x16' },
    { id: 35, image_name: 'bg_35_9x16' },
    { id: 36, image_name: 'bg_36_9x16' },
    { id: 37, image_name: 'bg_37_9x16' },
    { id: 38, image_name: 'bg_38_9x16' },
    { id: 39, image_name: 'bg_39_9x16' }
  ];
  const imageData = currentAspectRatio === '16:9' ? imageData16x9 : imageData9x16;

  useEffect(() => {
    setCurrentAspectRatio(aspectRatio);
  }, [aspectRatio]);
  return (
    <div className={styles.backgroundWrapper}>
      {imageData.map((item) => (
        <div
          key={item.id}
          className={`${styles.bgCard} ${selectedBackground === item.image_name ? styles.selected : ''
            }`}
          onClick={() => onBackgroundSelect(item)}
        >
          <Image
            preview={false}
            src={require(`../../../asset/images/background/${item.image_name}.gif`)}
          />
        </div>
      ))}
    </div>
  );
}
