import React, { useState, useEffect, useRef } from 'react';
import {
  VideoCameraOutlined,
  SmileOutlined,
  FilePdfOutlined,
  ReadOutlined,
  VideoCameraFilled,
  QuestionCircleOutlined,
  FilePptOutlined,
  FileTextOutlined,
  UploadOutlined,
  UpCircleOutlined, 
  DownCircleOutlined,
  InfoCircleOutlined,
  LinkOutlined
} from '@ant-design/icons';
import { Popover } from 'antd';
import {
  Button,
  Modal,
  Form,
  Input,
  Spin,
  Image,
  Result,
  Select,
  Upload,
  Tabs,
  message,
  Radio,
  Switch
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import CoursesList from 'shared/components/coursesList';
import VideosList from 'shared/components/coursesList/videosList';
import styles from './courses.module.scss';
import { VaTitle } from 'shared/components/typography';
import Generate from 'shared/components/generate';
import svg404 from './404.svg';
import Preview from 'shared/components/generate/preview';

import {
  getCoursesList,
  getCourseById,
  createCourse,
  getVideosList,
  clearCourseState
} from './redux/courseSlice';
import {
  createVideo,
  generateVideoByText,
  generateVideoByUrl
} from '../courseIndex/redux/topicContentSlice';
import toast from 'react-hot-toast';

const { Option } = Select;

export default function Courses() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const generateRef = useRef(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isGenerateDisabled, setIsGenerateDisabled] = useState(false);
  const [isPptDisabled, setIsPptDisabled] = useState(false);
  const [isCourseModalOpen, setIsCourseModalOpen] = useState(false);
  const [isShortVideoModalOpen, setIsShortVideoModalOpen] = useState(false);
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [videoName, setVideoName] = useState('');
  const [selectedShortData, setSelectedShortData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [actionType, setActionType] = useState('');
  const [shortActionType, setShortActionType] = useState('');
  const [isHelpVideoModalOpen, setIsHelpVideoModalOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState("https://www.youtube.com/embed/dePZEfENAQg?si=h2GUiGUiGEN2kgcW");
  const [isTextToVideoHelpModalOpen, setIsTextToVideoHelpModalOpen] = useState(false);
  const [textToVideoSrc, setTextToVideoSrc] = useState("");
  const [isWatermarkEnabled, setIsWatermarkEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    coursesListStatus,
    coursesList,
    videosListStatus,
    videosList,
    coursesListError,
    videosListError
  } = useSelector((state) => state.course);
  console.log(coursesListStatus, coursesList, coursesListError);
  console.log(videosListStatus, videosList, videosListError);
  const { status, course, error, isSearching } = useSelector((state) => state.course);
  console.log(status, course, error);

  const { TabPane } = Tabs;
  const { Dragger } = Upload;

  const [textToVideoForm] = Form.useForm();
  const [urlToVideoForm] = Form.useForm();

  useEffect(() => {
    if (
      !isShortVideoModalOpen &&
      !isGenerateModalOpen &&
      !isCourseModalOpen &&
      !isPreviewModalOpen
    ) {
      dispatch(getCoursesList());

      const fetchVideos = () => {
        console.log('Fetching videos');
        dispatch(getVideosList());
      };

      // Fetch videos immediately on mount
      fetchVideos();

      // Set an interval to fetch videos every 1 minute (60000 milliseconds)
      const intervalId = setInterval(() => {
        fetchVideos();
      }, 180000);

      // Clear the interval when the component unmounts
      return () => {
        console.log('Clearing interval');
        clearInterval(intervalId);
      };
    }
  }, [
    dispatch,
    isShortVideoModalOpen,
    isGenerateModalOpen,
    isCourseModalOpen,
    isPreviewModalOpen
  ]);

  useEffect(() => {
    dispatch(clearCourseState());
  }, []);

  const openCourse = (data) => {
    console.log(data);
    // dispatch(getCourseById({ course_id: data?.course_id }));
    navigate(`/courses/${data?.course_id}`);
  };

  const showCourseModal = () => {
    setIsCourseModalOpen(true);
  };

  const showShortVideoModal = () => {
    setIsButtonDisabled(true); // Disable the button when the modal opens
    setIsShortVideoModalOpen(true);
  };

  const handleCancelCourse = () => {
    setIsCourseModalOpen(false);
  };

  const handleCancelShortVideo = () => {
    setIsShortVideoModalOpen(false);
    setShowAdvancedOptions(false);
    form.resetFields();
  };

  const showGenerateModal = () => {
    setIsGenerateModalOpen(true);
  };
  const showHelpModal = () => {
    setIsHelpModalOpen(true);
  };

  const handleHelpModalCancel = () => {
    setIsHelpModalOpen(false);
  };

  const handleGenerateCancel = () => {
    setIsGenerateModalOpen(false);
    dispatch(getVideosList());
    textToVideoForm.resetFields();  // Reset Text form
    urlToVideoForm.resetFields();  // Reset URL form
  };

  const handleGenerateCancelBtn = () => {
    setIsGenerateModalOpen(false);
  };
  const showPreviewModal = () => {
    setIsPreviewModalOpen(true);
  };

  const handlePreviewCancel = () => {
    setIsPreviewModalOpen(false);
  };

  const shortData = (data) => {
    setSelectedShortData(data);
  };

  const onFinishCourse = async (values) => {
    let courseName = values.courseName;
    setVideoName(courseName);
    setLoading(true); // Start loading

    try {
      // let data = await dispatch(createCourse({ topic: courseName }));

      const resultAction = await dispatch(createCourse({ topic: courseName }));
      console.log(resultAction);
      console.log(status, course, error);

      // Check if the action was successful
      if (createCourse.fulfilled.match(resultAction)) {
        // Extract course ID from the payload
        console.log(resultAction);
        const courseId = resultAction?.payload?.data?.course?.course_id; // Adjust according to your response structure

        console.log('Course Created Successfully:', resultAction.payload);

        // Navigate to the new course page using the course ID
        navigate(`/courses/${courseId}`);

        // Optionally, you can refresh the course list
        dispatch(getCoursesList());

        // Optionally handle UI state changes or modal closures
        handleCancelCourse();
      } else {
        // Handle errors if needed
        console.error('Failed to create course:', resultAction.payload);
      }
    } catch (error) {
      console.error('Error creating course:', error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  const uploadProps = {
    name: 'file',
    multiple: false,
    action: '/upload', // Replace with your upload handling endpoint
    beforeUpload: (file) => {
      const isFileAllowed =
        file.type === 'application/pdf' ||
        file.type === 'application/vnd.ms-powerpoint';
      if (!isFileAllowed) {
        toast.error('You can only upload PDF or PPT files!');
      }
      return isFileAllowed || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        toast.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        toast.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleWatermarkToggleChange = (checked) => {
    setIsWatermarkEnabled(checked); // Update the state based on switch position (on/off)
    console.log('Watermark enabled:', checked ? 'true' : 'false'); // Log 'True' or 'False' based on the toggle status
  };

  const onFinishShortVideo = (values) => {
    console.log('video name----->>>>>');
    
    const videoName = values.courseName;
    const additionalContext = values.additionalContext || '';
    const aspectRatio = values.aspectRatio || '16:9';
    const slidesCount = values.noOfSlides || '5-10';
  
    // Use the switch state to determine watermark value
    const watermark = isWatermarkEnabled ? 'true' : 'false'; // Pass 'True' if switch is on, 'False' if off

    console.log('Watermark:', watermark); // For debugging purposes
  
    let ai_generate = false;
  
    if (shortActionType === 'shortGenerateByAI') {
      ai_generate = true;
      // Handle "Generate by AI" logic
      console.log('Generate by AI:', values);
      
      setVideoName(videoName);
      
      // API request to create video with the new features
      dispatch(createVideo({
        topic: videoName,
        ai_generate: 'true',
        additional_context: additionalContext,
        aspect_ratio: aspectRatio,
        slides_count: slidesCount,
        watermark: watermark
      }));
  
      handleCancelShortVideo();
      showGenerateModal();
      dispatch(getVideosList());
  
    } else if (shortActionType === 'shortCustomGenerate') {
      // Handle "Custom Generate" logic
      console.log('Custom Generate:', values);
  
      setVideoName(videoName);
      
      // API request to create video with the new features
      dispatch(createVideo({
        topic: videoName,
        ai_generate: 'false',
        additional_context: additionalContext,
        aspect_ratio: aspectRatio,
        slides_count: slidesCount,
        watermark: watermark
      }));
  
      handleCancelShortVideo();
      showGenerateModal();
      dispatch(getVideosList());
    }
  };

  const generateVideoContent = () => {
    console.log('Update Content-is triggered>>>>>>>>');
    if (generateRef.current) {
      generateRef.current.generateVideoContent(); // Call the method exposed by Generate component
      handlePreviewCancel();
    }
  };
  const updateScene = () => {
    if (generateRef.current) {
      generateRef.current.updateScene(); // Call the method exposed by Generate component
      setIsButtonDisabled(true); // Optionally disable the button again after updating
      setIsGenerateDisabled(false);
      setIsPptDisabled(false);
      textToVideoForm.resetFields();  // Reset Text form
      urlToVideoForm.resetFields();  // Reset URL form
    }
  };

  const handleBothActions = () => {
    setIsGenerateDisabled(false);
    generateVideoContent(); // Generates video content
    handleGenerateCancelBtn(); // Closes the modal
    textToVideoForm.resetFields();  // Reset Text form
    urlToVideoForm.resetFields();  // Reset URL form
  };

  const generatePptContent = () => {
    setIsPptDisabled(false);
    if (generateRef.current) {
      generateRef.current.generatePptContent();
      textToVideoForm.resetFields();  // Reset Text form
      urlToVideoForm.resetFields();  // Reset URL form
    }
  };

  const helpContent = (
    <div>
      <p>
        Check out the <strong>Short Video</strong> or{' '}
        <strong>Course Video</strong> or{' '}<strong>Text to Video</strong> to take
      </p>
      <p> first step for Video creation.</p>
    </div>
  );
  const helpvideoContent = (
    <div>
      <p>
        Please click this help before <strong>generating </strong> the video
      </p>
      <p> first step for creating a video.</p>
    </div>
  );

  const loadCourseVideoDetails = () => {
    if (
      (coursesListStatus === 'succeeded' &&
        coursesList?.data?.courses?.length > 0) ||
      (videosListStatus === 'succeeded' && videosList?.data?.videos?.length > 0)
    ) {
      return (
        <>    
          {videosListStatus === 'succeeded' &&
            videosList?.data?.videos?.length > 0 && (
              <div className={styles.featuredCourse}>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <VideoCameraFilled style={{ marginRight: '8px' }} />
                  <VaTitle level={4} text="Shorts Videos" />
                </div>
                <div className={styles.list}>
                  <VideosList
                    data={videosList?.data?.videos}
                    isProgress={false}
                    showPreviewModal={showPreviewModal}
                    showGenerateModal={showGenerateModal}
                    videoName={setVideoName}
                    shortData={shortData}
                  />
                </div>
              </div>
            )}
            {coursesListStatus === 'succeeded' &&
            coursesList?.data?.courses?.length > 0 && (
              <div className={styles.featuredCourse}>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <ReadOutlined style={{ marginRight: '8px' }} />
                  <VaTitle level={4} text="Your Courses" />
                </div>
                <div className={styles.list}>
                  <CoursesList
                    data={coursesList?.data?.courses}
                    openCourse={openCourse}
                  />
                </div>
              </div>
            )}
        </>
      );
    } else {
      if (isSearching) {
        return (
          <Result
            style={{ overflow: 'hidden', marginTop: '100px', }}
            title={
              <div
                style={{
                  fontSize: '18px',
                  fontWeight: 'initial',
                  color: 'GrayText',
                  textAlign: 'center',
                  marginTop: '-10px',
                }}
              >
                No search results found
              </div>
            }
            subTitle={
              <div
                style={{
                  fontSize: '14px',
                  fontWeight: 'normal',
                  color: '#FF9100',
                  textAlign: 'center',
                  marginTop: '5px',
                }}
              >
                Try adjusting your search criteria or keywords
              </div>
            }
          />
        );
      }else{
        return (
          <Result
            style={{ overflow: 'hidden' }}
            icon={<Image height={300} preview={false} src={svg404} />}
            title={
              <div style={{ fontSize: '18px', fontWeight: 'initial', color: 'GrayText', textAlign: 'center', marginTop: '-10px'}}>
                No content available at the moment<br></br>
                <span style={{color: '#FF9100', fontWeight:'bold'}}>Generate a Video to continue</span>
              </div>
            }
          />
        );
      }
    }
  };
  const handleUserInteraction = () => {
    setIsButtonDisabled(false); // Enable the button after any user interaction
    setIsGenerateDisabled(true);
    setIsPptDisabled(true);
  };

  const options = [
    {
      value: 'CODE_SLIDE',
      label: 'Code'
    },
    {
      value: 'CONTENT_SLIDE',
      label: 'Content'
    },
    {
      value: 'CONTENT_SLIDE_WITH_IMAGE',
      label: 'Content with Image'
    },
    {
      value: 'CONTENT_SLIDE_WITH_VIDEO',
      label: 'Content with Video'
    },
    {
      value: 'CONTENT_SLIDE_WITH_VIDEO_ONLY',
      label: 'Video'
    },
    {
      value: 'TITLE_SLIDE',
      label: 'Title'
    },
    {
      value: 'IMAGE_ONLY_SLIDE',
      label: 'Image'
    }
  ];

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onFinishTextToShortVideo = (values) => {
    console.log('video name----->>>>>');
    const videoName = values.courseName;
    const videoText = values.videoText;
  
    let ai_generate = 'false';
    let restrict = true;
  
    // Retrieve values from advanced options
    const aspectRatio = values.aspectRatio || '16:9';
    const noOfSlides = values.noOfSlides || '5-10';
    const watermark = isWatermarkEnabled; // Get watermark value
  
    if (actionType === 'generateByAI') {
      ai_generate = 'true';
      restrict = false;
  
      console.log('Generate by AI:', values);
      setVideoName(videoName);
      
      dispatch(
        generateVideoByText({
          topic: videoName,
          text: videoText,
          ai_generate: ai_generate,
          restrict: restrict,
          aspect_ratio: aspectRatio,
          slides_count: noOfSlides,
          watermark: watermark,
        })
      );
      
      showGenerateModal();
      dispatch(getVideosList());
          
      // Close the modal after video generation
      setIsModalOpen(false);
      form.resetFields();
      
    } else if (actionType === 'customGenerate') {
      console.log('Custom Generate:', values);
  
      setVideoName(videoName);
      
      dispatch(
        generateVideoByText({
          topic: videoName,
          text: videoText,
          ai_generate: ai_generate,
          restrict: restrict,
          aspect_ratio: aspectRatio,
          slides_count: noOfSlides,
          watermark: watermark,
        })
      );
      
      showGenerateModal();
      dispatch(getVideosList());

      // Close the modal after video generation
      setIsModalOpen(false);
      textToVideoForm.resetFields();  // Reset Text form
    }
  };
  
  const onFinishUrlToVideo = async (values) => {
    console.log("Form Submitted UrlToVideo", values); // Check if this logs
  
    const videoTopic = values.topic;
    const videoUrl = values.videoUrl;
    let ai_generate = 'true';
    const watermark = isWatermarkEnabled; // Get watermark value
  
    // Set loading to true when the generation starts
    setIsLoading(true);
  
    try {
      const response = await dispatch(
        generateVideoByUrl({
          topic: videoTopic,
          url: videoUrl,
          ai_generate: ai_generate,
          watermark: watermark,
        })
      );
  
      if (response.payload.data === 'Unable to generate video at this point. Try after sometime!') {
        setIsLoading(false);
        
        toast.error('Please provide a valid open-source URL to proceed.');
        return;
      }
  
      setIsLoading(false);
      showGenerateModal();
      dispatch(getVideosList());
      setIsModalOpen(false);
      urlToVideoForm.resetFields();
  
    } catch (error) {
      console.error('Error generating video:', error);
      
      setIsLoading(false);
      
      toast.error('An error occurred while generating the video.');
    }
  };  

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [watermarkOption, setWatermarkOption] = useState(''); // To handle watermark selection

  const toggleAdvancedOptions = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const handleWatermarkChange = (value) => {
    setWatermarkOption(value);
  };

  // Function to open modal and reset video source
  const openHelpVideoModal = () => {
    setIsHelpVideoModalOpen(true);
    setVideoSrc("https://www.youtube.com/embed/dePZEfENAQg?si=h2GUiGUiGEN2kgcW");
  };

  // Function to close modal and pause video
  const closeHelpVideoModal = () => {
    setIsHelpVideoModalOpen(false);
    setVideoSrc("");
  };

  // Function to open the Text to Video help modal and set the video link
  const openTextToVideoHelpModal = () => {
    setIsTextToVideoHelpModalOpen(true);
    setTextToVideoSrc("https://www.youtube.com/embed/pNEXzkGvFAg?si=kVuxafXyIsVnOoap");
  };

  // Function to close the modal and stop the video
  const closeTextToVideoHelpModal = () => {
    setIsTextToVideoHelpModalOpen(false);
    setTextToVideoSrc("");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.toolBar}>
        <VaTitle level={4} text="" />
        <div className={styles.sort}>
          <Button
            onClick={showShortVideoModal}
            icon={<VideoCameraOutlined />}
            type="primary"
          >
            Short Video
          </Button>
          {/* <Button
            onClick={showCourseModal}
            icon={<VideoCameraOutlined />}
            type="primary"
          >
            Course Video
          </Button> */}
          <Button
            onClick={showModal}
            icon={<FileTextOutlined />}
            type="primary"
            
          >
            Text to Video
          </Button>
          <Popover
            content={helpContent}
            title={<strong style={{ color: '#1890ff' }}>Helpful Tips</strong>}
            overlayStyle={{
              borderRadius: '10px',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
              backgroundColor: '#f0f0f0'
            }}
            trigger="hover"
          >
            <QuestionCircleOutlined
              style={{
                fontSize: '20px',
                cursor: 'pointer',
                color: '#1890ff',
                transition: 'color 0.3s ease-in-out'
              }}
              onMouseEnter={(e) => (e.currentTarget.style.color = '#ff6f00')}
              onMouseLeave={(e) => (e.currentTarget.style.color = '#1890ff')}
            />
          </Popover>
          {/* <Button
            onClick={(event) => generateContent(event, 'FileToVideo')}
            icon={<FilePdfOutlined />}
            type="primary"
          >
            PDF to Video
          </Button> */}
        </div>
      </div>
      {coursesListStatus === 'succeeded' || videosListStatus === 'succeeded' ? (
        <>{loadCourseVideoDetails()}</>
      ) : (
        <div style={{ textAlign: 'center', height: '500px' }}>
          <Spin
            size="large"
            className="spinner"
            style={{ display: 'block', margin: '0 auto', marginTop: '200px' }}
          />
          <div style={{ marginTop: '20px' }}>
            <h3>Loading, please wait...</h3>
          </div>
        </div>
      )}

      {/* Course Video Modal */}
      <Modal
        title="Generate Your Own Course"
        open={isCourseModalOpen}
        onCancel={() => {
          handleCancelCourse();
          form.resetFields(); // Reset the input fields when modal is closed
        }}
        maskClosable={true} // Prevents closing on click outside
        footer={[
          <Button key="back" onClick={handleCancelCourse}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Generate
          </Button>
        ]}
      >
        <div className={styles.formWrapper}>
          <Form
            name="generateCourse"
            form={form}
            onFinish={onFinishCourse}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Enter your Course Name:"
              name="courseName"
              rules={[
                {
                  required: true,
                  message: 'Please enter Course Name!'
                },
                {
                  max: 50,
                  message: 'Topic name cannot exceed 50 characters!'
                }
              ]}
            >
              <Input placeholder="Eg: Learn ChatGPT" />
            </Form.Item>
          </Form>
        </div>
        {loading && (
          <div className={styles.loaderContainer}>
            <Spin
              size="large"
              // style={{ display: 'block', margin: '0 auto', marginTop: '200px' }}
            />
            <p>Course Structure is Generating...</p>
          </div>
        )}
      </Modal>

      {/* Short Video Modal */}
      <Modal
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Generate Your Short Video</span>
            <Button
              icon={<InfoCircleOutlined />}
              onClick={openHelpVideoModal}
              style={{ marginLeft:'20px' }}
            >
              How to use
            </Button>
          </div>
        }
        open={isShortVideoModalOpen}
        onCancel={() => {
          handleCancelShortVideo();
        }}
        maskClosable={true}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', marginTop: '20px' }}>
            <Button
              type="primary"
              onClick={() => {
                setShortActionType('shortGenerateByAI');
                form.submit();
              }}
            >
              AI Generate
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setShortActionType('shortCustomGenerate');
                form.submit();
              }}
            >
              Custom Generate
            </Button>
          </div>
        ]}
      >
        <div className={styles.formWrapper}>
          <Form
            name="generateShortVideo"
            form={form}
            onFinish={onFinishShortVideo}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Enter your Topic name:"
              name="courseName"
              rules={[
                { required: true, message: 'Please enter Topic Name!' },
                { max: 500, message: 'Topic name cannot exceed 500 characters!' }
              ]}
            >
              <Input placeholder="Eg: What is Generative AI" />
            </Form.Item>

            {/* Clickable text for Advanced Options */}
            <div style={{ marginBottom: '-10px', display: 'flex', alignItems: 'center', marginTop: '10px' }}>
              <a onClick={toggleAdvancedOptions} style={{ display: 'flex', alignItems: 'center' }}>
                {showAdvancedOptions ? 'Hide Advanced Options' : 'Advanced Options'}
                {showAdvancedOptions ? (
                  <UpCircleOutlined style={{ marginLeft: '8px' }} />
                ) : (
                  <DownCircleOutlined style={{ marginLeft: '6px' }} />
                )}
              </a>
            </div>

            {/* Advanced Options Section */}
            {showAdvancedOptions && (
              <div style={{ marginTop: '20px' }}>
                {/* Additional Context Input */}
                <Form.Item
                  label="Additional Context:"
                  name="additionalContext"
                  rules={[{ required: false }]}
                >
                  <Input.TextArea
                    rows={2}
                    placeholder="Provide additional context for your topic (optional)"
                  />
                </Form.Item>

                {/* Aspect Ratio */}
                <Form.Item label="Aspect Ratio" name="aspectRatio" initialValue="16:9">
                  <Radio.Group>
                    <Radio value="16:9">16:9</Radio>
                    <Radio value="9:16">9:16</Radio>
                  </Radio.Group>
                </Form.Item>

                {/* No. of Slides */}
                <Form.Item label="No. of Slides" name="noOfSlides" initialValue="5-10">
                  <Select placeholder="Select the number of slides">
                    <Option value="5-10">5-10</Option>
                    <Option value="10-15">10-15</Option>
                    <Option value="15-20">15-20</Option>
                    <Option value="20-25">20-25</Option>
                  </Select>
                </Form.Item>

                {/* Watermark Toggle Option */}
                <Form.Item label="Watermark">
                    <Switch
                      checked={isWatermarkEnabled}
                      onChange={handleWatermarkToggleChange}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                    />
                </Form.Item>
              </div>
            )}
          </Form>
        </div>
      </Modal>

      {/* YouTube Modal */}
      <Modal
        title="How to Use"
        open={isHelpVideoModalOpen}
        onCancel={closeHelpVideoModal}
        footer={null}
        width={800}
        destroyOnClose={true}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {isHelpVideoModalOpen && videoSrc && (
            <iframe
              width="100%"
              height="450"
              src={videoSrc}
              title="Help Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
      </Modal>

      <Modal
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Generate Your Video Content</span>
            <Button
              icon={<InfoCircleOutlined />}
              onClick={openTextToVideoHelpModal}
              style={{ marginLeft: '20px' }}
            >
              How to use
            </Button>
          </div>
        }
        open={isModalOpen}
        onCancel={() => {
          handleCancel();
          setShowAdvancedOptions(false);
          textToVideoForm.resetFields();  // Reset Text form
          urlToVideoForm.resetFields();   // Reset URL form
        }}
        footer={null}
      >
        <Tabs defaultActiveKey="1">
          {/* Text to Video Tab */}
          <TabPane tab={<span><FileTextOutlined/>Text to Video</span>} key="1">
            <Form
              form={textToVideoForm}
              layout="vertical"
              onFinish={onFinishTextToShortVideo}
              style={{ marginTop: '20px' }}
            >
              <Form.Item
                label="Enter Title:"
                name="courseName"
                rules={[{ required: true, message: 'Please enter a title!' }]}
              >
                <Input placeholder="Title of your video content" />
              </Form.Item>

              <Form.Item
                label="Enter Text:"
                name="videoText"
                rules={[{ required: true, message: 'Please enter text content!' }]}
              >
                <Input.TextArea rows={4} placeholder="Text to convert into video" />
              </Form.Item>

              <div
                style={{ marginTop: '20px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}
              >
                <a onClick={toggleAdvancedOptions} style={{ display: 'flex', alignItems: 'center' }}>
                  {showAdvancedOptions ? 'Advanced Options' : 'Advanced Options'}
                  {showAdvancedOptions ? (
                    <UpCircleOutlined style={{ marginLeft: '8px' }} />
                  ) : (
                    <DownCircleOutlined style={{ marginLeft: '6px' }} />
                  )}
                </a>
              </div>

              {showAdvancedOptions && (
                <div>
                  <Form.Item label="Aspect Ratio" name="aspectRatio" initialValue="16:9">
                    <Radio.Group>
                      <Radio value="16:9">16:9</Radio>
                      <Radio value="9:16">9:16</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="No. of Slides" name="noOfSlides" initialValue="5-10">
                    <Select placeholder="Select the number of slides">
                      <Option value="5-10">5-10</Option>
                      <Option value="10-15">10-15</Option>
                      <Option value="15-20">15-20</Option>
                      <Option value="20-25">20-25</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item label="Watermark">
                    <Switch
                      checked={isWatermarkEnabled}
                      onChange={handleWatermarkToggleChange}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                    />
                  </Form.Item>
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '16px',
                  marginTop: '20px',
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setActionType('generateByAI');
                    textToVideoForm.submit();
                  }}
                >
                  AI Generate
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setActionType('customGenerate');
                    textToVideoForm.submit();
                  }}
                >
                  Custom Generate
                </Button>
              </div>
            </Form>
          </TabPane>

          {/* URL to Video Tab */}
          <TabPane tab={<span><LinkOutlined /> URL to Video</span>} key="2">
            <Form
              form={urlToVideoForm}
              layout="vertical"
              onFinish={onFinishUrlToVideo}
              onFinishFailed={(errorInfo) => {
                console.log("Form validation failed:", errorInfo); // Catch validation errors
              }}
              style={{ marginTop: '20px' }}
            >
              <Form.Item
                label="Enter Topic:"
                name="topic"
                rules={[{ required: true, message: 'Please enter a topic!' }]}
              >
                <Input placeholder="Topic of your video" />
              </Form.Item>

              <Form.Item
                label="Enter URL:"
                name="videoUrl"
                rules={[{ required: true, message: 'Please enter a URL!' }]}
              >
                <Input placeholder="URL to convert into video" />
              </Form.Item>

              <div style={{ marginTop: '20px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                <a onClick={toggleAdvancedOptions} style={{ display: 'flex', alignItems: 'center' }}>
                  {showAdvancedOptions ? 'Advanced Options' : 'Advanced Options'}
                  {showAdvancedOptions ? (
                    <UpCircleOutlined style={{ marginLeft: '8px' }} />
                  ) : (
                    <DownCircleOutlined style={{ marginLeft: '6px' }} />
                  )}
                </a>
              </div>

              {showAdvancedOptions && (
                <div>
                  <Form.Item label="Aspect Ratio" name="aspectRatio" initialValue="16:9">
                    <Radio.Group>
                      <Radio value="16:9">16:9</Radio>
                      <Radio value="9:16">9:16</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="No. of Slides" name="noOfSlides" initialValue="5-10">
                    <Select placeholder="Select the number of slides">
                      <Option value="5-10">5-10</Option>
                      <Option value="10-15">10-15</Option>
                      <Option value="15-20">15-20</Option>
                      <Option value="20-25">20-25</Option>
                    </Select>
                  </Form.Item>

                  {/* Watermark Toggle Option */}
                  <Form.Item label="Watermark">
                    <Switch
                      checked={isWatermarkEnabled}
                      onChange={handleWatermarkToggleChange}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                    />
                  </Form.Item>
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '16px',
                  marginTop: '20px',
                }}
              >
                <Button type="primary" htmlType="submit">
                  Generate from URL
                </Button>
                {isLoading && <Spin size="large" />}
              </div>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
      {/* YouTube Modal for Text to Video */}
      <Modal
        title="How to use"
        open={isTextToVideoHelpModalOpen}
        onCancel={closeTextToVideoHelpModal}
        footer={null}
        width={800}
        destroyOnClose={true}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {isTextToVideoHelpModalOpen && textToVideoSrc && (
            <iframe
              width="100%"
              height="450"
              src={textToVideoSrc}
              title="Help Video for Text to Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
      </Modal>

      {/* Generate Modal */}
      <Modal
        title={videoName}
        open={isGenerateModalOpen}
        onCancel={handleGenerateCancel}
        width={'99vw'}
        centered
        height={'99vh'}
        maskClosable={true} // Prevents closing on click outside
        footer={[
          <Button key="cancel" onClick={handleGenerateCancel}>
            Cancel
          </Button>,
          <Button
            key="back"
            onClick={updateScene}
            disabled={isButtonDisabled}
            type="primary"
          >
            Update Scene
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleBothActions}
            disabled={isGenerateDisabled}
          >
            Generate Video
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={generatePptContent}
            disabled={isPptDisabled}
          >
            Download PPT
          </Button>
        ]}
      >
        <div
          className={styles.formWrapper}
          onClick={handleUserInteraction}
          onKeyUp={handleUserInteraction}
        >
          <Generate ref={generateRef} />
        </div>
      </Modal>

      {/* Preview Modal */}
      <Modal
        id="video-panel"
        title={videoName}
        open={isPreviewModalOpen}
        width={'99vw'}
        centered
        height={'99vh'}
        onCancel={handlePreviewCancel}
        destroyOnClose={true}
        footer={[]}
        maskClosable={true} // Prevents closing on click outside
      >
        <div className={styles.formWrapper}>
          <Preview selectedShortData={selectedShortData} />
        </div>
      </Modal>
    </div>
  );
}
