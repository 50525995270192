import React, { useState, useEffect } from 'react';
import styles from './generate.module.scss';
import { Button, Upload, Popconfirm, Spin } from 'antd';
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import toast from 'react-hot-toast';

export default function Images({
  onFileSelect,
  onFileRemove,
  fileList,
  currentSceneImageName
}) {
  console.log(currentSceneImageName);

  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = ({ fileList }) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setUploadedFileName(file.name);
      setLoading(true); 
      // Simulating async file upload for demo purposes (you can replace this with actual upload logic)
      setTimeout(() => {
        setLoading(false); 
        onFileSelect({ fileList });
      }, 1000);
    } else {
      setUploadedFileName(null);
    }
  };
  const handleDeleteImage = async () => {
    try {
      if (onFileRemove) {
        await onFileRemove(); // Remove the file (from S3, for example)
      }
      setUploadedFileName(null);
      currentSceneImageName('');
      // toast.success('File removed successfully');
      return true; // Ant Design requires a boolean to indicate if the file was successfully removed
    } catch (error) {
      // toast.error('Failed to remove file: ' + error.message);
      return false; // Return false if removal fails
    }
  };

  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cse.google.com/cse.js?cx=43763cc9f91d74032';

    // Append the script to the body or head
    document.body.appendChild(script);

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array means this runs once when component mounts

  const helpImgUploadContent = (
    <div>
      <h3>Image Search & Upload:</h3>
      <li>
        <ol>
          <strong>1. Search:</strong> Type a keyword and search.
        </ol>
        <ol>
          <strong>2. Select:</strong> Right-click on an image.
        </ol>
        <ol>
          <strong>3. Download:</strong> Save it.
        </ol>
        <ol>
          <strong>4. Upload:</strong> Click the upload button (📤).
        </ol>
      </li>
    </div>
  );

  return (
    <div
      className={styles.backgroundWrapper}
      style={{ gridTemplateColumns: 'repeat(1, 1fr)', overflow: 'hidden', position: 'relative' }}
    >
      {/* Upload Button */}
	  <div style={{ display: 'flex', alignItems: 'center' }}>
      <Upload
        multiple={false}
        onChange={handleFileChange}
        beforeUpload={() => false}
        fileList={fileList}
        accept="image/*,video/mp4"
      >
        <Button
          type="primary"
          size="medium"
          shape="round"
          style={{
            marginBottom: '10px',
            marginRight: '20px',
            padding: '0 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          icon={<UploadOutlined />}
        >
          Upload
        </Button>
      </Upload>
	  {/* Show spinner when uploading */}
        {loading && (
          <Spin size="medium" style={{ alignItems:'center', marginBottom:'10px'}} />
        )}
      </div>

      {/* Uploaded Image Info & Delete Button */}
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative'}}>
        {currentSceneImageName !== null && currentSceneImageName !== '' ? (
          <>
         {/* Delete Icon */}
            <Popconfirm
            title="Are you sure to delete current Scene Image?"
            onConfirm={handleDeleteImage}
            okText="Yes"
            cancelText="No"
            >
              <Button
                shape="round"
                className={styles.deleteIcon}
                icon={<DeleteOutlined style={{ color: 'red' }} />}
                style={{ marginRight: '10px' }}
              />
            </Popconfirm>
			   {/* Uploaded File Name */}
              <p
                className={styles.uploadedFileName}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '150px',
                  position: 'relative',
                }}
              >
                {currentSceneImageName || uploadedFileName}
              </p>
            </>
          ) : (
            <p
              className={styles.noImageText}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '150px',
                color:'#507687'
              }}
            >
               <strong>No image uploaded</strong>
            </p>
          )}
        </div>

	  {/* Help and Note */}
      <div style={{ marginTop: '10px' }}>
        <p style={{ marginLeft: '20px' }}>
          <strong>Note:</strong> Use the search bar below to find more images.
          <Popover content={helpImgUploadContent}>
            <QuestionCircleOutlined
              style={{
                fontSize: '14px',
                cursor: 'pointer',
                marginLeft: '10px'
              }}
            />
          </Popover>
        </p>
      </div>
      <div className="gcse-search"></div>
    </div>
  );
}
