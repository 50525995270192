import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { sessionStatus } from './redux/billingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { VaText, VaTitle } from 'shared/components/typography';
import { Button, Result } from 'antd';
import styles from './billing.module.scss';

const PaymentSuccess = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { paySessionStatus, paymentSessionStatus, paymentSessionStatusError } =
    useSelector((state) => state.billing);
  console.log('inside of the payment success ->>>>>>>>>');

  const location = useLocation();

  useEffect(() => {
    console.log(location.search);
    const query = new URLSearchParams(location.search);
    console.log(query);

    const sessionId = query.get('session_id');
    console.log(sessionId);
    if (sessionId) {
      // Assume checkoutPricing dispatch will return the clientSecret
      const response = dispatch(sessionStatus({ session_id: sessionId }));
      console.log(response);
      // Optionally, you can fetch additional information about the session here if needed
      toast.success(<>Payment was successful! Thankyou for your purchase.</>);
    }
  }, []);

  return (
    <div className={styles.paymentSuccess}>
      {paySessionStatus === 'succeeded' &&
      paymentSessionStatus.status === 'complete' ? (
        <div style={{ display: 'grid' }}>
          <Result
            status="success"
            title="Successfully Purchased !"
            subTitle="You can Generate videos without any Interruption..."
            extra={[
              <Button type="primary" key="console" onClick={() => navigate('/courses')}>
              Go to Dashboard
            </Button>
            ]}
          />
        </div>
      ) : (
        <div style={{ display: 'grid' }}>
          <Result
            status="error"
            title="Payment failed, Please try again."
            subTitle="Your payment has not been processed."
            extra={[
              <Button type="default" key="console" onClick={() => navigate('/courses')}>
                Go to Dashboard
              </Button>,
              <Button key="buy" onClick={() => navigate('/billings')} type="primary">Try Again</Button>,
            ]}
          >
          </Result>
        </div>
      )}
    </div>
  );
};

export default PaymentSuccess;
